import { toast } from 'react-toastify';
import { decode } from 'html-entities';

export function convertUnixToDateTime(unixDate) {
  const date = new Date(unixDate * 1000);
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

export function formateDateTime(unixDate) {
  const date = new Date(unixDate / 1000);
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

export function convertUnixToDate(unixDate) {
  const date = new Date(unixDate * 1000);
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`;
}

export function colorVariation(r, g, b, opacity) {
  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}

export function LightenDarkenColor(colorCode, amount) {
  var usePound = false;

  if (colorCode[0] === '#') {
    colorCode = colorCode.slice(1);
    usePound = true;
  }

  var num = parseInt(colorCode, 16);

  var r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  var b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  var g = (num & 0x0000ff) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export const formatDate = (date, isUnix = false, withTime = false) => {
  if (isUnix) {
    date = new Date(date * 1000);
  } else {
    date = new Date(date);
  }
  var options = { year: 'numeric', month: 'long', day: 'numeric' };
  if (withTime) {
    options.hour = '2-digit';
    options.minute = '2-digit';
    options.hour12 = true;
  }

  return date.toLocaleDateString('en-US', options);
};

export const handleCopyToClipBoard = (
  id,
  message = 'Code is copied to clipboard'
) => {
  const el = document.createElement('textarea');
  if (!el) {
    return;
  }
  el.value = document.getElementById(id).innerText;
  if (window.navigator && window.navigator.clipboard) {
    window.navigator.clipboard.writeText(el.value).then(toast.success(message));
  } else {
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast.success(message);
  }
};

export const clearUserLocalStorage = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('user_info');
  localStorage.removeItem('display_name');
  localStorage.removeItem('first_name');
  localStorage.removeItem('last_name');
  localStorage.removeItem('nonce');
  localStorage.removeItem('profile_image');
  localStorage.removeItem('user_type');
};

export const clearSwitchedUserLocalData = () => {
  localStorage.removeItem('switched_back_to');
};
export const updateSwitchedBackToUserData = (data) => {
  localStorage.setItem('switched_back_to', JSON.stringify(data));
};

export function ucFirst(string) {
  return (string && string.charAt(0).toUpperCase() + string.slice(1)) || string;
}

export function statusTextColor(status) {
  switch (status.toLowerCase()) {
    case 'active':
      return 'WpdSuccessAlert';
    case 'complete':
      return 'WpdSuccessAlert';
    case 'publish':
      return 'WpdSuccessAlert';
    case 'pending':
      return 'WpdWarningAlert';
    case 'inactive':
      return 'WpdWarningAlert';
    default:
      return 'WpdDangerAlert';
  }
}

export function getErrorMessage(error, defaultMessage = 'Operation failed') {
  if (error && error.data && error.data.message) {
    if (typeof error.data.message === 'object') {
      let message = '';
      Object.keys(error.data.message).map((key) => {
        message = message + error.data.message[key];
      });
      return decode(message);
    } else {
      return decode(error.data.message);
    }
  } else {
    return decode(defaultMessage);
  }
}

export function isLoggedIn() {
  return (
    localStorage.getItem('access_token') &&
    localStorage.getItem('access_token').length
  );
}

export const validateFormFields = (formFields, rules) => {
  const errors = {};
  for (const field in formFields) {
    if (formFields.hasOwnProperty(field)) {
      if (rules.hasOwnProperty(field)) {
        const fieldRules = rules[field];
        let value = '';
        if (typeof formFields[field] !== 'object') {
          value = formFields[field];
        } else {
          value = formFields[field].value;
        }
        if (fieldRules.hasOwnProperty('required')) {
          let valid = true;

          if (typeof value === 'undefined') {
            valid = false;
          } else {
            if (typeof value === 'boolean') {
              if (!value) {
                valid = false;
              }
            } else {
              if (!value.trim().length) {
                valid = false;
              }
            }
          }
          if (!valid) {
            if (typeof fieldRules.required !== 'object') {
              errors[field] = `${ucFirst(field)} is required`;
            } else {
              errors[field] = fieldRules.required.message;
            }
            continue;
          }
        }
        /* eslint-disable */
        if (fieldRules.hasOwnProperty('email') && formFields[field].length) {
          const pattern = new RegExp(
            /*/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i*/
            /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
          );
          if (!pattern.test(formFields[field])) {
            if (typeof fieldRules.email !== 'object') {
              errors[field] = `${ucFirst(field)} must be a valid email`;
            } else {
              errors[field] = fieldRules.email.message;
            }
          }
        }
        if (fieldRules.hasOwnProperty('username')) {
          const pattern = new RegExp(/^\S+$/);
          if (!pattern.test(formFields[field])) {
            if (typeof fieldRules.username !== 'object') {
              errors[field] = `${ucFirst(field)} must be a valid username`;
            } else {
              errors[field] = fieldRules.username.message;
            }
          }
        }
        /* eslint-enable */

        if (fieldRules.hasOwnProperty('minLength')) {
          if (formFields[field].length < fieldRules.minLength.value) {
            if (typeof fieldRules.minLength !== 'object') {
              errors[field] = `${ucFirst(field)} must be greater than ${
                fieldRules.minLength.value
              } character`;
            } else {
              errors[field] = fieldRules.minLength.message;
            }
          }
        }
        if (fieldRules.hasOwnProperty('maxLength')) {
          if (formFields[field].length > fieldRules.maxLength.value) {
            if (typeof fieldRules.maxLength !== 'object') {
              errors[field] = `The length of ${ucFirst(field)} must be under ${
                fieldRules.maxLength.value
              } character`;
            } else {
              errors[field] = fieldRules.maxLength.message;
            }
          }
        }
        if (fieldRules.hasOwnProperty('url')) {
          const urlPattern =
            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+)(\/[^\s]*)?$/;
          if (!urlPattern.test(formFields[field])) {
            if (typeof fieldRules.url !== 'object') {
              errors[field] = `${ucFirst(field)} must be a valid url`;
            } else {
              errors[field] = fieldRules.url.message;
            }
          }
        }
        if (fieldRules.hasOwnProperty('match')) {
          const matchField =
            typeof fieldRules.match !== 'object'
              ? fieldRules.match
              : fieldRules.match.value;
          const matchFieldValue = formFields[matchField];
          if (matchFieldValue && matchFieldValue !== formFields[field]) {
            if (typeof fieldRules.match !== 'object') {
              errors[field] = `The ${ucFirst(field)} must match with ${ucFirst(
                matchField
              )}`;
            } else {
              errors[field] = fieldRules.match.message;
            }
          }
        }
      }
    }
  }
  if (Object.keys(errors).length) {
    return {
      valid: false,
      errors,
    };
  }
  return {
    valid: true,
    errors: undefined,
  };
};

export const downloadItem = (name, response) => {
  const extension = name.split('.').slice(-1)[0];
  let type = 'application/json';
  if (extension === 'pdf') {
    type = 'application/pdf';
  }
  if (extension === 'zip') {
    type = 'application/zip';
  }
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  let blob = new Blob([response], { type: type });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.target = '_blank';
  a.download = name;
  a.click();
};

export function isLifetime(expiration) {
  return (
    expiration.toString().toLowerCase() === 'lifetime' ||
    expiration.toString().toLowerCase() === 'never'
  );
}

export function getExpiration(license) {
  if (license && license.expiration) {
    if (
      formatDate(new Date(license.expiration * 1000)).toLowerCase() !==
      'invalid date'
    ) {
      return formatDate(new Date(license.expiration * 1000));
    } else {
      if (isLifetime(license.expiration)) {
        return 'Never';
      } else {
        return ucFirst(license.expiration);
      }
    }
  }
  return 'Unknown';
}

export function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function isServerError(error) {
  return error && error.data?.code === 'internal_server_error';
}

export function getAgencyBundleProducts(priceId) {
  if (parseInt(priceId) === 1) {
    return [
      {
        name: 'Essential Addons',
        image: '',
      },
    ];
  }
}

export function trimFormFields(fields) {
  for (const key in fields) {
    if (fields.hasOwnProperty(key)) {
      if (
        !Array.isArray(fields[key]) &&
        typeof fields[key] !== 'object' &&
        typeof fields[key] !== 'undefined'
      ) {
        fields[key] = fields[key].trim();
      }
    }
  }
  return fields;
}

export function twoDecimalFraction(number) {
  if (number) {
    number = number.toString();
    const match = number.match(/^-?\d+(?:\.\d{0,2})?/);
    if (match) {
      return match['0'];
    }
    return number;
  }
  return number;
}

export function isProductionMode() {
  return process.env.REACT_APP_MODE.toString() === 'production';
}

export function maskEmail(email) {
  const arr = email.split('@');
  return (
    arr[0].substring(0, 2) +
    '*****' +
    arr[0].substring(arr[0].length) +
    '@' +
    arr[1]
  );
}
